import * as React from "react"

import SEO from "../components/seo"
import Page from "../components/Page"

const styles = {
    header: {
        color: "#f9f9f9",
        textAlign: "center" as "center"
    },
    content: {
        color: "#f9f9f9",
        textAlign: "center" as "center"
    }
}

const NotFoundPage = () => (
    <Page>
        <SEO title="404: Not found" />
        <div className="wrapper" style={{ marginTop: 48, marginBottom: 48 }}>
            <h1 style={styles.header}>NOT FOUND</h1>
            <p style={styles.content}>This page doesn't exist</p>
        </div>
    </Page>
)

export default NotFoundPage
